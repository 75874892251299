import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';

import { setIsLoggedIn } from '@pumpkincare/shared';

import { USER_INITIAL_DATA, USER_SELF_QUERY } from './user-self-query';
import { getUserSelf, logIn, logOut } from './user-service';
import { transformUser } from './user-utils';

export function useLogOut() {
  const queryClient = useQueryClient();

  return function () {
    return logOut()
      .then(() => {
        setIsLoggedIn(false);

        queryClient.setQueryData([USER_SELF_QUERY], USER_INITIAL_DATA);
        Sentry.setUser(null);
      })
      .catch(error => {
        setIsLoggedIn(true);

        throw error;
      });
  };
}

export function useLogIn() {
  const queryClient = useQueryClient();

  return function (login, password) {
    return logIn(login, password)
      .then(() => {
        setIsLoggedIn(true);

        return getUserSelf();
      })
      .then(response => {
        const { users: user, invoices } = response;

        Sentry.setUser({ id: user.id });
        queryClient.setQueryData([USER_SELF_QUERY], transformUser(user, invoices));

        return response;
      })
      .catch(error => {
        setIsLoggedIn(false);

        throw error;
      });
  };
}
